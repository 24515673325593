<template>
  <div class="container">
    <img class="image" src="@/assets/images/PowerAttorney.png" alt="" srcset="">
    <!-- <p class="title">《授权同意书》</p>
    <p>强生(上海) 医疗器材有限公司:</p>
    <p>强生(中国) 医疗器材有限公司:</p>
    <p>本人，[]，医生参考编号:[请填写 iPower 系统内 MDM Code]，同意并授权贵司在以下事宜中(“授权合作事宜”(请勾选所适用的情形) :</p>
    <ul class="ul">
      <li>使用本人的形象、肖像和声音用于拍摄有关[活动名称《如果是系列活动，请注明系列活动名称及时间地点)的视频《视频名称/主题》](视频详情详见附件)，并同意贵司通过任何媒介公开播放、传播该视频，该等视频的著作权及其他相关权利归贵司所有;</li>
      <li>发布、转载或以其他方式合理使用本人撰写或拍摄的有关[请填入内容主题]的文章或视频[请填入文章/视频名称] (文章摘要/视频详情详见附件) :</li>
      <ul class="child_ul">
        <li>学术课件</li>
        <li>学术演讲多媒体课件</li>
        <li>学术病例</li>
        <li>学术观点</li>
        <li>学术演讲视频</li>
        <li>其他()(请具体写明)</li>
      </ul>
      <li>拍摄或/和使用包含本人形象、肖像的照片用于[](照片(如已有)详见附件)</li>
      <li>[使用、发布本人拍摄的图片]</li>
    </ul>
    <p>上述授权不受时间、地域、使用平台及使用方式之限制(包括但不限于公开发表、复制、传播、引用、修改、删减、汇编、翻译等)，且不可撤销。本人确认贵司无须就上述授权向本人支付任何报酬、补偿、补贴或费用。贵司在使用本人的形象、肖像、照片、初频、文字作品等时可以注明本人的姓名、职位及所属单位。贵司遵守本授权书约定的前提下，本人承诺:</p>
    <p style="text-indent: 2em;">1.不会就与上述材料有关的包括但不限于发表权、署名权、保护作品完整权、复制权、信息网络传播权、修改权、改编权等著作权权利以及肖像权、名誉权、隐私权等权利,向贵司或经贵司授权使用上述材料的第三方提出任何要求或诉讼;</p>
    <p style="text-indent: 2em;">2.本人为上述材料的实际著作权人/肖像权人，有权向贵司及贵司的关联公司进行授权。上述材料不存在侵犯任何第三方权利的内容。若上述材料包含他人肖像，本人保证在授权之日前已获得肖像权人授权并有权许可给贵司及贵司的关联公司使用。因本人承诺不实而引起的法律责任，由本人自行承担。</p>
    <p style="text-indent: 2em;">3.本人承诺上述材料(包括所涉及的专利或专利申请信息、技术秘密) 没有虚假记载误导性或者重大遗漏，本人对其内容的真实性、准确性和完整性承担法律责任。</p>
    <p class="title">附录 知情同意书</p>
    <p>为订立、履行《综合服务框架协议》和/或前述授权合作事宜所必需，本人有义务提供，且强生（上海）医疗器材有限公司和强生（中国）医疗器材有限公司（合称“贵司”或“强生”）可以限于前述目的处理本人的如下个人信息（请根据实际所需处理的个人信息的情况进行勾选）：</p>
    <ul class="ul">
      <li>姓名、所属机构、职位、肖像 </li>
      <li>获奖情况、荣誉称号、教育信息、工作经历</li>
      <li>学术演讲视频 </li>
      <li>[《视频名称/主题》] （提示：本项仅需在适用上一页授权合作事宜第一项时勾选，且应当与第一项中所载明的视频名称/主题保持一致）</li>
      <li>[请填入其它需收集、处理的个人信息，如有]</li>
    </ul>
    <p>本人理解，强生将力求使用合理的组织、技术和管理措施来保护我提供的个人信息，防止未经授权或意外访问、销毁、修改、封锁、复制、散布及其他未经授权的处理。强生将基于法律法规规定或限于实现前述目的所必要的最短期限内保存我的个人信息。</p>
    <p>一般情况下，强生不会向第三方提供我提供的个人信息。但本人理解，为了满足前述目的，强生可能委托技术服务提供商来处理我提供的个人信息，包括提供云服务、去标识化技术措施等。强生会根据法律法规的要求，与技术服务提供商严格根据本知情同意书的内容，约定委托处理的目的、期限、处理方式、个人信息的种类、保护措施以及双方的权利和义务等，并对技术服务提供商的个人信息处理活动进行监督。</p>
    <p>本人可以通过联系RA-JNJCNMP-ichannel@its.jnj.com以行使我享有的个人信息主体权利，包括查阅、复制、更正、补充，以及满足法律要求和与贵司约定的情况下删除我的个人信息。</p>
    <p style="text-indent: 2em;">本人承诺：</p>
    <p style="text-indent: 2em;">1.如需要向贵司提供非本人（包括但不限于病人、亲属、同事等）的个人信息，本人已取得个人同意或其他处理个人信息的合法性基础。以同意作为处理个人信息的合法性基础的，本人向个人告知并取得的同意已充分涵盖本人履行授权合作事宜的需要，不存在违反《个人信息保护法》《医疗机构病历管理规定》等法律法规、主管部门相关规定、标准的情况；</p>
    <p style="text-indent: 2em;">2.如本人受强生委托，以强生的名义收集其他个人信息主体的同意的，本人已依法取得其个人同意作为强生处理其个人信息的合法性基础，并保证本人向个人告知并取得的同意已充分涵盖其与强生履行授权合作事宜的需要，不存在违反《个人信息保护法》《医疗机构病历管理规定》等法律法规、主管部门相关规定、标准的情况；</p>
    <p style="text-indent: 2em;">3.如涉及不满十四周岁未成年人个人信息的，除前述内容外，还符合《儿童个人信息网络保护规定》要求，例如已征得其监护人的同意。本人理解，本知情同意书与《综合服务框架协议》第十二条“隐私保护与肖像权使用”之约定不一致的，以本知情同意书为准。</p>
    <p class="title">签字页</p>
    <p>本人已认真阅读并知悉以上全部内容，了解贵司将基于本知情同意书声明的目的、方式和范围处理我提供的个人信息，我理解我的主动勾选同意即表示我已充分阅读并同意勾选框下对应的内容。</p>
    <p>本人独立、自愿作出如下决定：</p>
    <ul class="ul">
      <li><strong>同意贵司处理我提供的敏感个人信息</strong></li>
      <p>我同意贵司按照本知情同意书声明的范围和方式处理我的敏感个人信息。以粗体标识的敏感个人信息属于一旦遭遇泄露或非法使用，容易导致人格尊严受到侵害或者人身、财产安全受到危害的个人信息，但属于贵司基于前述目的或我履行《综合服务框架协议》和/或前述授权合作事宜所必需的个人信息。</p>
      <li><strong>同意贵司向境外传输我提供的个人信息</strong></li>
      <p>由于强生通过遍布全球的关联公司以及信息系统开展日常业务运营，贵司可能需要将我提供的个人信息提供给贵司境外的合作伙伴或关联公司。贵司将履行法律、法规和相关监管所要求的数据出境安全管理义务，并采取去标识化等必要的安全措施保护我的个人信息。</p>
      <li><strong>同意贵司公开处理我提供的个人信息</strong></li>
      <p>基于履行前述授权合作事宜所必需，我同意贵司按照授权合作事宜中所载明的方式公开我提供的个人信息，包括但不限于公布于贵司的公众号、在线学习平台等。</p>
    </ul>
    <p><strong>本人已根据强生的委托依法收集其他个人信息主体的同意，本人已知悉并同意本授权书的全部内容。</strong></p> -->
  </div>
</template>

<script>
export default {
  name: 'PowerAttorney',
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  min-height: 100%;
  font-size: 12px;
  box-sizing: border-box;
  background-color: #F8F8F8;
  .title {
    font-size: 15px;
    text-align: center;
    font-weight: bold;
  }
  p {
    line-height: 2;
  }
  .ul {
    list-style-type: disc;
    margin-left: 30px;
    li {
      line-height: 2;
    }
  }
  .child_ul {
    list-style-type: circle;
    margin-left: 14px;
    li {
      line-height: 2;
    }
  }
  .image {
    width: 100%;
  }
}
</style>